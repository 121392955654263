import React from 'react';
import PageHero from '../components/page-hero';
import '../assets/sass/about.scss';
import '../assets/css/about.css';
import ImgContainer from '../components/img-container';

export default class Partners extends React.Component {
    render() {

        return(

            <div className="page-content about">

                <svg viewBox="0 0 1 1" style={{ position: 'absolute' }}>
                    <defs>

                        <clipPath id="our-values-mask" clipPathUnits="objectBoundingBox" >

                            <path d="M0,0.097 C0,0.044,0.017,0,0.038,0 H0.962 C0.983,0,1,0.044,1,0.097 V0.696 C1,0.743,0.987,0.784,0.968,0.792 L0.507,0.999 C0.502,1,0.498,1,0.493,0.999 L0.032,0.792 C0.013,0.784,0,0.743,0,0.696 V0.097" />

                        </clipPath>


                    </defs>
                </svg>

                <section className='page-hero clip-path-ellipse position-relative'>

                    <div className='content'>

                        <img className='position-absolute top-0 w-100 h-100' src={ process.env.PUBLIC_URL + "/assets/img/about.jpeg" } alt="" />
                        <div className='layout-width'>
                            <div className='d-grid'>
                                <div className='d-flex align-items-center'>

                                    <h1 className='page-hero-header text-center'>Meet Our <span className="text-yellow">Partners</span>  </h1>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className='section-2'>

                    <div className='layout-width'>
                        <div className='grid-2-row headerXlWithParagraph'>
                            <p className='text-black f-32 fw-400'>
                                We work with a variety of partner brands who enable us to deliver and perform our obligations to players in redeeming items from our marketplace with their Gudu Tokens.

                                Below are partner brands we are working with;
                            </p>
                        </div>
                        <div className="partnersContainer">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/tampico-beverages3235.logowik1.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/untitled-1_4001.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/logo31.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/infinix-brand-logo-phone-symbol-name-black-design-china-mobile-illustration-free-vector1.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/COMPU_G-011.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/logo5.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/download(1)2.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/newpat.jpeg" } alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/goil.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/itel.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/gandor.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='partner-card'>
                                        <div className='partner-card-img'>
                                            <img className="img-fluid" src={ process.env.PUBLIC_URL + "/assets/partners/rana.png" } alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

        );

    }
}