import React from 'react';
import '../assets/sass/home.scss';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import $ from 'jquery';

const settingsPageHero = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    easing: 'easeOutElastic'
  };

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
};



export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {moveCount: 0};
    }

    async hintYoutubeClick(e) {

        $('.main.water-ripple').css({ top: e.pageY - 95, left: e.pageX });
        $('.main.water-ripple .wave').addClass('animated');

    }

    componentDidMount() {

        $('.page-hero').on('mouseleave', () => {
            $('.slick-prev').css('opacity', 0);
            $('.slick-next').css('opacity', 0);

        });

        $('.page-hero').on('mouseenter', function (e) {
            $('.slick-prev').css('opacity', 0.7);
            $('.slick-next').css('opacity', 0.7);

        });

        $('.page-hero').on('mousemove', (e) => {

                let startTimer;
                let endTimer;

                if(this.state.moveCount == 0) {

                    this.hintYoutubeClick(e);
        
                    startTimer = setTimeout(() => {
        
                        $('.main.water-ripple').css('opacity', 1);
            
                    }, 1250);

                }

                
                endTimer = setTimeout(() => {

                    $('.main.water-ripple').css('opacity', 0);
                    this.setState({ moveCount: 1 });

                }, 46000);

        });
    
    }

    render() {

        return (
            <div className='page-content home'>

                <section className='page-hero'>

                    <Slider {...settingsPageHero} >
                        <a href="https://www.youtube.com/watch?v=wvuHb7nqyl0" class="content position-relative">
                            <div className=''>
                                
                                <video className='w-100 h-100 position-absolute top-0' src={ process.env.PUBLIC_URL + "/assets/video/video.mp4" } autoplay="true" muted loop></video>

                            </div>
                            <div className="main water-ripple">
                                <div className="wave wave1"></div>
                                <div className="wave wave2"></div>
                                <div className="wave wave3"></div>
                            </div>
                        </a>
                        <div className='content'>
                            <img className='w-100 h-100' src={ process.env.PUBLIC_URL + "/assets/img/homepage-hero-img-2.jpeg" } alt="" />

                        </div>
                        <div className='content'>
                            <img className='w-100 h-100' src={ process.env.PUBLIC_URL + "/assets/img/homepage-hero-img-1.jpeg" } alt="" />

                        </div>
                    </Slider>

                </section>

                <section className='section-2'>

                    <div className='layout-width'>

                        <div className='grid-2-row headerXlWithParagraph'>
                            <h1 className='header-xl text-black has-underline underline-blue fit-content'>Welcome to GUDU</h1>
                            <p className='text-black f-32 fw-400'>
                            Africa is the fastest growing video game market in the world. We are committed to building a thriving gaming economy in Africa by building games everyone can enjoy and creating an avenue for engineering and entrepreneurial careers in the gaming industry.
                            </p>
                        </div>

                    </div>

                </section>

                <section className='section-3 bg-black bg-clip-badge'>
                    <div className='layout-width'>

                        <div className='d-flex justify-content-between flex-wrap'>
                            <h1 className='header-xl text-white has-underline underline-gradient'>Our Games</h1>
                            <Link to="/games" reloadDocument className="f-32 fw-700 text-gradient-fill mt-3">View All 
                                <span className='icon'>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.05 31.3666C23.7333 31.3666 23.4166 31.25 23.1666 31C22.6833 30.5166 22.6833 29.7166 23.1666 29.2333L32.4 20L23.1666 10.7666C22.6833 10.2833 22.6833 9.48328 23.1666 8.99995C23.65 8.51662 24.45 8.51662 24.9333 8.99995L35.05 19.1166C35.5333 19.6 35.5333 20.4 35.05 20.8833L24.9333 31C24.6833 31.25 24.3666 31.3666 24.05 31.3666Z" fill="#F8E930"/>
                                        <path d="M33.8833 21.25H5.83331C5.14998 21.25 4.58331 20.6833 4.58331 20C4.58331 19.3167 5.14998 18.75 5.83331 18.75H33.8833C34.5666 18.75 35.1333 19.3167 35.1333 20C35.1333 20.6833 34.5666 21.25 33.8833 21.25Z" fill="#F8E930"/>
                                    </svg>
                                </span>
                            </Link>
                        </div>

                        <div className='spacing'>
                            <div className='grid-2 column-gap-lg'>
                                <div className='video-container'>
                                    <div className='outer-container'>
                                        <div className='overlay'></div>
                                        <div className='inner-container'>
                                            <img src={ process.env.PUBLIC_URL + "/assets/img/gudu-run.jpeg" } alt="gudu runner" />
                                        </div>
                                    </div>
                                </div>
                                <div className='grid-3-row mt-5 headerLgWithParagraphWithButton'>

                                    <h1 className='header-lg text-white has-underline underline-gradient'>Gudu Run</h1>
                                    <p className='text-white f-32 fw-500'>
                                    Gudu Run is an endless runner game available on mobile only. Players run through the streets collecting coins, avoiding obstacles and staying alive. Players use the coins collected to redeem real world products.
                                    </p>
                                    <Link to='/games' className='btn btn-outline-light btn-lg br-20 text-uppercase'>see more</Link>

                                </div>
                            </div>
                        </div>

                        <div className='spacing'>
                            <div className='grid-2 column-gap-lg'>
                                <div className='grid-3-row mt-5 headerLgWithParagraphWithButton'>

                                    <h1 className='header-lg text-white has-underline underline-gradient'>Gudu FX</h1>
                                    <p className='text-white f-32 fw-500'>
                                    Gudu FX is an endless driver game available on mobile only. Players run through the streets collecting coins, dodging obstacles and avoiding crush. Players use the coins collected to redeem real world products.
                                    </p>
                                    <Link to='/games' className='btn btn-outline-light btn-lg br-20 text-uppercase'>see more</Link>

                                </div>
                                <div className='video-container justify-self-lg-end'>
                                    <div className='outer-container'>
                                        <div className='overlay'></div>
                                        <div className='inner-container'>
                                            <img src={ process.env.PUBLIC_URL + "/assets/img/gudu-fx.jpeg" } alt="gudu runner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
     
            </div>

        )

    }

}