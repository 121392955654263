import $ from 'jquery';

class CommonJS {
    
    setPageHeroHeight() {

        $('.page-hero .content').css('height', window.innerHeight * 1.1 + 'px')

    }
    
}

let commonJs = new CommonJS();

export default commonJs;